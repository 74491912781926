import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReplaySubject, retry } from 'rxjs';
import { PopUpModalComponent } from 'src/app/components/pop-up-modal/pop-up-modal.component';
import { TimelineComponent } from 'src/app/components/timeline/timeline.component';
import { BibleBooksService } from 'src/app/services/bible-book/bible-book.service';
import { DialogsService } from 'src/app/services/dialog/dialog.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { ProjectProfileService } from 'src/app/services/project-profile/project-profile.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
})
export class CreateProjectComponent implements OnInit {
  /************************************
   *  Variable
   ***********************************/
  changingFacilitatorValue: any = new ReplaySubject(1);
  changingMttValue: any = new ReplaySubject(1);
  changingtranslateValue: any = new ReplaySubject(1);
  projectProfile: FormGroup | any;
  ProjectTypeError: boolean | any;
  TestmentError: boolean | any;
  dateError: boolean | any;
  facilitatorData: any[] | any;
  duplicateUsers: any[] | any;
  mttData: any[] | any;
  translateData: any[] | any;
  countriesList: any[] | any;
  regions: any[] | any;
  languages: any[] | any;
  sourceLanguages: Array<object> | any;
  isLanguageTranslated: boolean | any;
  isJesusFilmProject: boolean | any; //selectedProjectType:string;
  videos: Array<string> = ['Jesus Film', 'Sign Language'];
  audioOptionType: Array<string> = [
    'OBT',
    'OBT & TEXT',
    'Session/Episode',
    'Stories',
  ];
  checkProjectTypeValue: any = null;
  audioTypeValue: any = null;
  videoTypeValue: any;
  testamentValue: any;
  offlineValue: boolean = false;
  isBookAllocation: boolean = false;
  noCrossCheck: boolean = false;
  noCommunityCheck: boolean = false;
  noCitCheck: boolean = false;
  noConsultantCheck: boolean = false;

  /************************************
   *  Constructor
   ***********************************/
  constructor(
    private bibleBooksService: BibleBooksService,
    private formBuilder: FormBuilder,
    private userDataService: UserDataService,
    private projectProfileService: ProjectProfileService,
    private router: Router,
    private dialog: MatDialog,
    private dialogService: DialogsService,
    private languageService: LanguageService
  ) {
    this.isJesusFilmProject = false;
  }

  /************************************
   * Life cycle method
   ***********************************/
  ngOnInit(): void {
    this.createFormControls();
    this.addMtt();
    this.addFacilitator();
    this.addConsultant();
    this.addCit();
    this.addTechnician();
    this.addCoOrdinator();
    this.addtranslateManager();
    this.addCommunity();
    this.getRegions();
    this.getSourceLanguages();
  }

  checkProjectType(event: any) {
    this.audioTypeValue = null;
    this.videoTypeValue = null;
    if (event.value === 'text') {
      this.checkProjectTypeValue = 'text';
      this.isJesusFilmProject = false;
      return;
    }
    this.checkProjectTypeValue = event.value;
  }
  /************************************
   * Send Data
   ***********************************/
  sendData(data: any) {
    this.facilitatorData = data.filter((user: any) => {
      if (user.role === 'Facilitator') {
        return user;
      }
    });
    this.mttData = data.filter((user: any) => {
      if (user.role === 'MTT') {
        return user;
      }
    });
    this.translateData = data.filter((user: any) => {
      if (user.role === 'Translation Manager') {
        return user;
      }
    });

    this.changingFacilitatorValue.next(this.facilitatorData);
    this.changingMttValue.next(this.mttData);
    this.changingtranslateValue.next(this.translateData);
  }

  /************************************
   * Create form control and input field
   ***********************************/
  createFormControls() {
    this.projectProfile = new FormGroup({
      projectName: new FormControl('', Validators.required),
      country: new FormControl(''),
      region: new FormControl(''),
      language: new FormControl('', Validators.required),
      projectLocation: new FormControl('', Validators.required),
      majorLanguage1: new FormControl('', Validators.required),
      majorLanguage2: new FormControl(''),
      majorLanguage3: new FormControl(''),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      testmentType: new FormControl(''),
      oldTestament: new FormControl(''),
      newTestament: new FormControl(''),
      partner: new FormControl('', Validators.required),
      facilitators: this.formBuilder.array([]),
      mtts: this.formBuilder.array([]),
      consultants: this.formBuilder.array([]),
      cits: this.formBuilder.array([]),
      technicians: this.formBuilder.array([]),
      coordinators: this.formBuilder.array([]),
      translateManagers: this.formBuilder.array([]),
      communities: this.formBuilder.array([]),
      offlinefeature: new FormControl(''),
      isBookAllocated: new FormControl(''),
      audio: new FormControl(''),
      text: new FormControl(''),
      videoType: new FormControl(''),
      projectType: new FormControl('', Validators.required),
    });
  }

  /************************************
   *  Create MTT
   ***********************************/
  createMtt() {
    return this.formBuilder.group({
      mtt: ['', Validators.required],
    });
  }

  /************************************
   *  Add MTT
   ***********************************/
  addMtt() {
    const control = <FormArray>this.projectProfile.controls['mtts'];
    const addrCtrl = this.createMtt();
    control.push(addrCtrl);
  }

  /************************************
   *  Delete MTT
   ***********************************/
  deleteMtt(i: number) {
    if (this.projectProfile.controls['mtts'].value.length > 1) {
      const control = <FormArray>this.projectProfile.controls['mtts'];
      control.removeAt(i);
    } else {
      return;
    }
  }

  /************************************
   *  Create Facilitator
   ***********************************/
  createFacilitator() {
    return this.formBuilder.group({
      facilitator: ['', Validators.required],
    });
  }

  /************************************
   *  Add Facilitator
   ***********************************/
  addFacilitator() {
    const control = <FormArray>this.projectProfile.controls['facilitators'];
    const addrCtrl = this.createFacilitator();
    control.push(addrCtrl);
  }

  /************************************
   *  Delete Facilitator
   ***********************************/
  deleteFacilitator(i: number) {
    if (this.projectProfile.controls['facilitators'].value.length > 1) {
      const control = <FormArray>this.projectProfile.controls['facilitators'];
      control.removeAt(i);
    } else {
      return;
    }
  }
  /************************************
   *  Create Consultant
   ***********************************/
  createConsultant() {
    return this.formBuilder.group({
      consultant: ['', Validators.required],
    });
  }

  /************************************
   *  Add Consultant
   ***********************************/
  addConsultant() {
    const control = <FormArray>this.projectProfile.controls['consultants'];
    const addrCtrl = this.createConsultant();
    control.push(addrCtrl);
  }

  /************************************
   * Delete Consultant
   ***********************************/
  deleteConsultant(i: number) {
    if (this.projectProfile.controls['consultants'].value.length > 1) {
      const control = <FormArray>this.projectProfile.controls['consultants'];
      control.removeAt(i);
    } else {
      return;
    }
  }

  /************************************
   *  Create CIT
   ***********************************/
  createCit() {
    return this.formBuilder.group({
      cit: ['', Validators.required],
    });
  }

  /************************************
   *  Add CIT
   ***********************************/
  addCit() {
    const control = <FormArray>this.projectProfile.controls['cits'];
    const addrCtrl = this.createCit();
    control.push(addrCtrl);
  }
  /************************************
   *  Delete CIT
   ***********************************/
  deleteCit(i: number) {
    if (this.projectProfile.controls['cits'].value.length > 1) {
      const control = <FormArray>this.projectProfile.controls['cits'];
      control.removeAt(i);
    } else {
      return;
    }
  }

  /************************************
   *  Create Technician
   ***********************************/
  createTechnician() {
    return this.formBuilder.group({
      technician: ['', Validators.required],
    });
  }

  /************************************
   *Add Technician
   ***********************************/
  addTechnician() {
    const control = <FormArray>this.projectProfile.controls['technicians'];
    const addrCtrl = this.createTechnician();
    control.push(addrCtrl);
  }

  /************************************
   * Delete Technician
   ***********************************/
  deleteTechnician(i: number) {
    if (this.projectProfile.controls['technicians'].value.length > 1) {
      const control = <FormArray>this.projectProfile.controls['technicians'];
      control.removeAt(i);
    } else {
      return;
    }
  }

  /************************************
   *   Co Ordinator
   ***********************************/
  createCoOrdinator() {
    return this.formBuilder.group({
      coordinator: ['', Validators.required],
    });
  }

  addCoOrdinator() {
    const control = <FormArray>this.projectProfile.controls['coordinators'];
    const addrCtrl = this.createCoOrdinator();
    control.push(addrCtrl);
  }

  deleteCoOrdinator(i: number) {
    if (this.projectProfile.controls['coordinators'].value.length > 1) {
      const control = <FormArray>this.projectProfile.controls['coordinators'];
      control.removeAt(i);
    } else {
      return;
    }
  }

  // ********************************
  // Translation Manager
  // ********************************

  createtranslateManager() {
    return this.formBuilder.group({
      translateManager: ['', Validators.required],
    });
  }

  addtranslateManager() {
    const control = <FormArray>(
      this.projectProfile.controls['translateManagers']
    );
    const addrCtrl = this.createtranslateManager();
    control.push(addrCtrl);
  }

  deletetranslateManager(i: number) {
    if (this.projectProfile.controls['translateManagers'].value.length > 1) {
      const control = <FormArray>(
        this.projectProfile.controls['translateManagers']
      );
      control.removeAt(i);
    } else {
      return;
    }
  }

  createCommunity() {
    return this.formBuilder.group({
      community: ['', Validators.required],
    });
  }

  addCommunity() {
    const control = <FormArray>this.projectProfile.controls['communities'];
    const addrCtrl = this.createCommunity();
    control.push(addrCtrl);
  }

  deleteCommunity(i: number) {
    if (this.projectProfile.controls['community'].value.length > 1) {
      const control = <FormArray>this.projectProfile.controls['community'];
      control.removeAt(i);
    } else {
      return;
    }
  }

  /************************************
   *  Validate Testiment Type
   ***********************************/
  validateTestimentType(event: any) {
    this.testamentValue = event.value;
  }

  /************************************
   *  Valid Project Type
   ***********************************/
  validateProjectType() {
    this.isJesusFilmProject = false;
    const audio = this.projectProfile.get('audio').value;
    const text = this.projectProfile.get('text').value;
    const videoType = this.projectProfile.get('videoType').value;
    if (!(audio || text || videoType)) {
      this.ProjectTypeError = true;
    } else {
      this.ProjectTypeError = false;
      videoType === 'Jesus Film'
        ? (this.isJesusFilmProject = true)
        : (this.isJesusFilmProject = false);
    }
  }
  /************************************
   *  Date Validation
   ***********************************/
  dateValidation() {
    const startDate = this.projectProfile.get('startDate').value;
    const endDate = this.projectProfile.get('endDate').value;
    if (new Date(startDate) > new Date(endDate)) {
      this.dateError = true;
    } else {
      this.dateError = false;
    }
  }

  /************************************
   *  Get Regions
   ***********************************/
  getRegions() {
    this.languageService.getRegions().subscribe((response) => {
      this.regions = this.alphaOrder(response, 'regionName');
    });
  }

  /************************************
   * Get Countries
   ***********************************/
  getCountries(event: any) {
    this.projectProfile.controls['country'].setValue(null);
    const regionId = event.value.id;
    this.languageService
      .getCountriesByRegion(regionId)
      .subscribe((response) => {
        this.countriesList = this.alphaOrder(response, 'countryName');
      });
  }

  /************************************
   *  Get language
   ***********************************/
  getLanguages(event: any) {
    this.projectProfile.controls['language'].setValue(null);
    const countryId = event.value.id;
    this.languageService
      .getLanguagesByCountry(countryId)
      .subscribe((response) => {
        this.languages = this.alphaOrder(response, 'languageName');
      });
  }

  /************************************
   *  Get Source Language
   ***********************************/
  getSourceLanguages() {
    this.languageService.getSourcLanguages().subscribe((response) => {
      this.sourceLanguages = this.alphaOrder(response, 'language');
    });
  }

  alphaOrder(list: any, fieldName: any) {
    return list.sort((a: any, b: any) => {
      if (a[fieldName] > b[fieldName]) return 1;
      else if (a[fieldName] < b[fieldName]) return -1;
      else return 0;
    });
  }

  /************************************
   * Validate Language
   ***********************************/
  validateLanguage() {
    if (
      this.projectProfile.get('language').value ===
        this.projectProfile.get('majorLanguage1').value ||
      this.projectProfile.get('language').value ===
        this.projectProfile.get('majorLanguage2').value ||
      this.projectProfile.get('language').value ===
        this.projectProfile.get('majorLanguage3').value
    ) {
      this.isLanguageTranslated = true;
    } else {
      this.isLanguageTranslated = false;
    }
  }

  /************************************
   *  Create project
   *  Submit button , will send data to the backend
   ***********************************/
  // createProject() {
  //   this.validateProjectType();
  //   // this.validateTestimentType();
  //   this.dateValidation();
  //   const initialConsultant = this.projectProfile.get('consultants').value;
  //   const initialMtts = this.projectProfile.get('mtts').value;
  //   const initialFacilitator = this.projectProfile.get('facilitators').value;
  //   const initialCit = this.projectProfile.get('cits').value;
  //   const initialTechnician = this.projectProfile.get('technicians').value;
  //   const initialOpsManager = this.projectProfile.get('opsManagers').value;
  //   const users = [
  //     initialConsultant,
  //     initialMtts,
  //     initialFacilitator,
  //     initialCit,
  //     initialTechnician,
  //     initialOpsManager,
  //   ];
  //   this.duplicateUsers = this.projectProfileService.validateUserDuplication(
  //     ...users
  //   );
  //   if (
  //     this.ProjectTypeError ||
  //     this.dateError ||
  //     this.TestmentError ||
  //     this.duplicateUsers.length
  //   ) {
  //     window.scrollTo(0, 100);
  //     return;
  //   }

  //   this.dialogService.confirm();
  //   let videoType = this.projectProfile.get('videoType').value;
  //   const typeofProject = {
  //     audio: !!this.projectProfile.get('audio').value,
  //     text: !!this.projectProfile.get('text').value,
  //     //obt: videoType == 'obt' ? true : false,
  //     jesusFilm: videoType == 'Jesus Film' ? true : false,
  //     signLanguage: videoType == 'Sign Language' ? true : false,
  //   };
  //   const consultant = initialConsultant.map((consultants: any) => {
  //     return { _id: consultants.consultant._id };
  //   });
  //   const facilitator = initialFacilitator.map((facilitators: any) => {
  //     return { _id: facilitators.facilitator._id };
  //   });

  //   const mtts = initialMtts.map((MTT: any) => {
  //     return { _id: MTT.mtt._id };
  //   });
  //   const cit = initialCit.map((CIT: any) => {
  //     return { _id: CIT.cit._id };
  //   });

  //   const technicians = initialTechnician.map((technicians: any) => {
  //     return { _id: technicians.technician._id };
  //   });
  //   const opsManagers = initialOpsManager.map((opsManagers: any) => {
  //     return { _id: opsManagers.opsManagers._id };
  //   });
  //   const projectData = {
  //     projectName: this.projectProfile.get('projectName').value,
  //     projectType: typeofProject,
  //     country: this.projectProfile.get('country').value.countryName,
  //     region: this.projectProfile.get('region').value.regionName,
  //     language: this.projectProfile.get('language').value,
  //     projectLocation: this.projectProfile.get('projectLocation').value,
  //     majorLanguage1: this.projectProfile.get('majorLanguage1').value,
  //     majorLanguage2:
  //       this.projectProfile.get('majorLanguage2').value === undefined
  //         ? ''
  //         : this.projectProfile.get('majorLanguage2').value,
  //     majorLanguage3:
  //       this.projectProfile.get('majorLanguage3').value === undefined
  //         ? ''
  //         : this.projectProfile.get('majorLanguage3').value,
  //     startDate: this.projectProfile.get('startDate').value,
  //     endDate: this.projectProfile.get('endDate').value,
  //     oldTestament: !!this.projectProfile.get('oldTestament').value,
  //     newTestament: !!this.projectProfile.get('newTestament').value,
  //     partner: this.projectProfile.get('partner').value,
  //     //others: !!this.projectProfile.get('others').value,
  //     facilitators: facilitator,
  //     mtts: mtts,
  //     consultants: consultant,
  //     cits: cit,
  //     technicians: technicians,
  //     opsManagers: opsManagers,
  //     offlinefeature: !!this.projectProfile.get('offlinefeature').value,
  //   };
  //   // this.projectProfileService.createProject(projectData).subscribe(
  //   //   (projectsData) => {
  //   //     if (projectsData instanceof HttpResponse) {
  //   //       if (projectsData.status === 200) {
  //   //         const response = projectsData.body as any;
  //   //         if (JSON.parse(response).message) {
  //   //           this.dialogService.closeModal();
  //   //           this.dialog.open(PopUpModalComponent, {
  //   //             data: {
  //   //               message: JSON.parse(response).message,
  //   //             },
  //   //           });
  //   //           return;
  //   //         }
  //   //         this.projectProfile.reset();
  //   //         this.dialogService.closeModal();
  //   //         this.router.navigate(['home']);
  //   //         this.changingFacilitatorValue.next();
  //   //         this.changingMttValue.next();
  //   //       }
  //   //     }
  //   //   },
  //   //   (error) => {
  //   //     if (error instanceof HttpErrorResponse) {
  //   //       this.dialog.open(PopUpModalComponent, {
  //   //         data: {
  //   //           message: 'Project Not Created',
  //   //         },
  //   //       });
  //   //     }
  //   //   }
  //   // );

  // }

  /************************************
   *  Get user By language
   ***********************************/
  getUsersByLanguage(language: string) {
    this.projectProfile.controls.facilitators.reset();
    this.projectProfile.controls.mtts.reset();
    this.userDataService
      .getUsersByLanguage(language.toLowerCase())
      .subscribe((userData) => {
        this.sendData(userData);
      });
  }

  checkAudioValueType(event: any) {
    this.audioTypeValue = event.value;
  }

  checkVideoType(event: any) {
    this.projectProfileService.isJesusFilmProject(event.value);
    this.testamentValue = null;
    if (event.value === 'Jesus Film') {
      this.isJesusFilmProject = true;
    } else if (event.value === 'Sign Language') {
      this.isJesusFilmProject = false;
    }
    this.videoTypeValue = event.value;
  }

  checkOfflineStatus(event: any) {
    this.offlineValue = event.checked;
  }

  checkIsBookAllocation(event: any) {
    this.isBookAllocation = event.checked;
  }
  checknoCrossCheck(event: any) {
    this.noCrossCheck = event.checked;
  }
  checknoCommunityCheck(event: any) {
    this.noCommunityCheck = event.checked;
  }
  checknoCitCheck(event: any) {
    this.noCitCheck = event.checked;
  }
  checknoConsultantCheck(event: any) {
    this.noConsultantCheck = event.checked;
  }

  submitData() {
    const typeofProject = {
      audio: this.checkProjectTypeValue === 'audio' ? true : false,
      text:
        this.audioTypeValue == 'OBT & TEXT' ||
        this.checkProjectTypeValue == 'text' ||
        this.videoTypeValue === 'Sign Language'
          ? true
          : false,
      audioType: this.audioTypeValue,
      jesusFilm: this.videoTypeValue === 'Jesus Film' ? true : false,
      signLanguage: this.videoTypeValue === 'Sign Language' ? true : false,
      //videoType: this.videoTypeValue
    };

    const initialConsultant = this.projectProfile.get('consultants').value;
    const initialMtts = this.projectProfile.get('mtts').value;
    const initialFacilitator = this.projectProfile.get('facilitators').value;
    const initialCit = this.projectProfile.get('cits').value;
    const initialTechnician = this.projectProfile.get('technicians').value;
    const initialCoordinator = this.projectProfile.get('coordinators').value;
    const initialTranslateManager =
      this.projectProfile.get('translateManagers').value;
    const intialCommunities = this.projectProfile.get('communities').value;
    const users = [
      initialConsultant,
      initialMtts,
      initialFacilitator,
      initialCit,
      initialTechnician,
      initialCoordinator,
      initialTranslateManager,
      intialCommunities,
    ];

    const consultant = initialConsultant.map((consultants: any) => {
      return { _id: consultants.consultant._id };
    });
    const facilitator = initialFacilitator.map((facilitators: any) => {
      return { _id: facilitators.facilitator._id };
    });

    const mtts = initialMtts.map((MTT: any) => {
      return { _id: MTT.mtt._id };
    });
    const cit = initialCit.map((CIT: any) => {
      return { _id: CIT.cit._id };
    });
    const technicianData = initialTechnician.map((tech: any) => {
      return { _id: tech.technician._id };
    });
    const coordinatorData = initialCoordinator.map((coor: any) => {
      return { _id: coor.coordinator._id };
    });
    const translationData = initialTranslateManager.map(
      (translateManagers: any) => {
        return { _id: translateManagers.translateManager._id };
      }
    );
    const communityData = intialCommunities.map((communities: any) => {
      return { _id: communities.community._id };
    });

    const sendDataToBackend = {
      projectName: this.projectProfile.get('projectName').value,
      projectType: typeofProject,
      country: this.projectProfile.get('country').value.countryName,
      region: this.projectProfile.get('region').value.regionName,
      language: this.projectProfile.get('language').value,
      projectLocation: this.projectProfile.get('projectLocation').value,
      majorLanguage1: this.projectProfile.get('majorLanguage1').value,
      majorLanguage2:
        this.projectProfile.get('majorLanguage2').value === undefined
          ? ''
          : this.projectProfile.get('majorLanguage2').value,
      majorLanguage3:
        this.projectProfile.get('majorLanguage3').value === undefined
          ? ''
          : this.projectProfile.get('majorLanguage3').value,
      startDate: this.projectProfile.get('startDate').value,
      endDate: this.projectProfile.get('endDate').value,
      oldTestament: this.testamentValue === 'oldTestament' ? true : false,
      newTestament: this.testamentValue === 'newTestament' ? true : false,
      partner: this.projectProfile.get('partner').value,
      facilitators: facilitator,
      mtts: mtts,
      consultants: consultant,
      cits: cit,
      technicians: technicianData,
      translateManagers: translationData,
      coordinators: coordinatorData,
      community: communityData,
      offlinefeature: this.offlineValue,
      isBookAllocated: this.isBookAllocation,
      showTimeLine: this.projectProfileService.getTimeLineInfo(),
      noCrossCheck: this.noCrossCheck,
      noCommunityCheck: this.noCommunityCheck,
      noCitCheck: this.noCitCheck,
      noConsultantCheck: this.noConsultantCheck,
    };

    this.projectProfileService.createProject(sendDataToBackend).subscribe(
      async (projectsData) => {
        if (projectsData instanceof HttpResponse) {
          if (projectsData.status === 200) {
            const response = projectsData.body as any;
            if (JSON.parse(response).ThankYou) {
              this.dialog.open(PopUpModalComponent, {
                data: {
                  message: JSON.parse(response).ThankYou,
                },
              });
              await wait();
              this.projectProfile.reset();
              this.dialog.closeAll();
              this.router.navigate(['/dashboard']);
              // return;
            }
            // this.projectProfile.reset();
            // this.dialogService.closeModal();
            // this.router.navigate(['/dashboard']);
            // this.changingFacilitatorValue.next();
            // this.changingMttValue.next();
          }
        }
      },
      (error) => {
        if (error instanceof HttpErrorResponse) {
          this.dialog.open(PopUpModalComponent, {
            data: {
              message: 'Project Not Created',
            },
          });
        }
      }
    );
  }
}

const wait = async () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('afd');
    }, 1500);
  });
};
