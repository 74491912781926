<div class="flex items-center sm-w-100">
    <div [formGroup]="communityGroup" class="grid grid-cols-3  sm-grid-cols-1 grill sm-w-100">
        <mat-form-field color="accent" class="formfield">
            <mat-label>Community</mat-label>
            <mat-select (selectionChange)="setValue($event.value)" formControlName="community" required>
                <mat-option *ngFor="let community of communities" [value]="community">
                    {{community.firstName}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="communityGroup.controls.community.errors?.required">Please select Community</mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="formfield mx-12">
            <input matInput type="text" placeholder="Community Email" [(ngModel)]="email"
                [ngModelOptions]="{standalone: true}" disabled>
        </mat-form-field>
        <mat-form-field color="accent" class="formfield">
            <input matInput type="text" placeholder="Community Number" [(ngModel)]="phNo"
                [ngModelOptions]="{standalone: true}" disabled>
        </mat-form-field>
    </div>
    <mat-icon (click)="showUser()"><span class="material-symbols-outlined"> av_timerss </span></mat-icon>
</div>