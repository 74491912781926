<mat-card class="font-primary projectCreate">
  <mat-card-header class="background-primary border-top-5">
    <mat-card-title class="font-primary">Create Project</mat-card-title>
  </mat-card-header>
  <form [formGroup]="projectProfile">
    <div *ngIf="duplicateUsers && duplicateUsers.length" class="dup-user">
      <ul *ngFor="let user of duplicateUsers">
        <li>{{ user.role }} with {{ user.email }} added multiple times.</li>
      </ul>
    </div>
    <mat-card-content>
      <div class="grid grid-cols-4 sm-grid-cols-1 grill">
        <mat-form-field color="accent" class="mr-8">
          <input matInput placeholder="Project Name" formControlName="projectName" required />
          <mat-error *ngIf="projectProfile.controls.projectName.errors?.required">Please enter project name
          </mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="mr-8">
          <input matInput placeholder="Project Location" formControlName="projectLocation" required />
          <mat-error *ngIf="projectProfile.controls.projectLocation.errors?.required">Please enter project location
          </mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="matformfield">
          <mat-label>Select Region</mat-label>
          <mat-select formControlName="region" required ngDefaultControl (selectionChange)="getCountries($event)">
            <mat-option *ngFor="let region of regions" [value]="region">{{ region.regionName }}
            </mat-option>
          </mat-select>
          <mat-error> Please select a region </mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="matformfield1 mr-8">
          <mat-label>Select Country</mat-label>
          <mat-select formControlName="country" required ngDefaultControl (selectionChange)="getLanguages($event)">
            <mat-option *ngFor="let country of countriesList" [value]="country" class="mat-option-custom">
              {{ country.countryName }}
            </mat-option>
          </mat-select>
          <mat-error> Please select a country </mat-error>
        </mat-form-field>
      </div>

      <div class="grid grid-cols-4 sm-grid-cols-1 grill">
        <mat-form-field color="accent" class="matformfield">
          <mat-label>Source Language 1</mat-label>
          <mat-select formControlName="majorLanguage1" #majorLang1 required (selectionChange)="validateLanguage()">
            <mat-option *ngFor="let language of sourceLanguages" [value]="language.language">
              {{ language.language }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="projectProfile.controls.majorLanguage1.errors?.required">Please select SourceLanguage 1
          </mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="matformfield">
          <mat-label>Source Language 2</mat-label>
          <mat-select formControlName="majorLanguage2" #majorLang2 (selectionChange)="validateLanguage()">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let language of sourceLanguages" [value]="language.language">
              {{ language.language }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="projectProfile.controls.majorLanguage2.errors">Please select Source Language 2
          </mat-error>
          <mat-hint *ngIf="majorLang1.value && majorLang1.value === majorLang2.value" style="color: red">
            Please select another source languauge
          </mat-hint>
        </mat-form-field>
        <mat-form-field color="accent" class="matformfield">
          <mat-label>Source Language 3</mat-label>
          <mat-select formControlName="majorLanguage3" #majorLang3 (selectionChange)="validateLanguage()">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let language of sourceLanguages" [value]="language.language">
              {{ language.language }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="projectProfile.controls.majorLanguage3.errors?.required">Please select Source Language 3
          </mat-error>
          <mat-hint *ngIf="
              (majorLang1.value && majorLang1.value === majorLang3.value) ||
              (majorLang2.value && majorLang2.value === majorLang3.value)
            " style="color: red">Please select another source languauge
          </mat-hint>
        </mat-form-field>
        <mat-form-field color="accent" class="matformfield">
          <mat-label>Target Language</mat-label>
          <mat-select formControlName="language" required (selectionChange)="
              getUsersByLanguage($event.value); validateLanguage()
            " #projectLang>
            <mat-option *ngFor="let language of languages" [value]="language.languageName">
              {{ language.languageName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="projectProfile.controls.language.errors?.required">Please select language
          </mat-error>
          <mat-hint *ngIf="
              (majorLang1.value && majorLang1.value === projectLang.value) ||
              (majorLang2.value && majorLang2.value === projectLang.value) ||
              (majorLang3.value && majorLang3.value === projectLang.value)
            " style="color: red">
            {{ projectLang.value }} is already translated,select other language.
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="grid grid-cols-3  sm-grid-cols-1 grill mr-2">
        <mat-form-field color="accent" class="matformfield1 mr-8">
          <input matInput placeholder="Enter Partner" formControlName="partner" required />
          <mat-error *ngIf="projectProfile.controls.partner.errors?.required">Please enter partner name
          </mat-error>
        </mat-form-field>
        <mat-form-field class="matformfield1 mr-8">
          <input matInput [matDatepicker]="startdate" placeholder="Start-date" formControlName="startDate" required />
          <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
          <mat-datepicker #startdate></mat-datepicker>
          <mat-error *ngIf="projectProfile.controls.startDate.errors?.required">Please select date
          </mat-error>
        </mat-form-field>
        <mat-form-field class="matformfield1 mr-8">
          <input matInput [matDatepicker]="endDate" placeholder="End-date" formControlName="endDate"
            (dateChange)="dateValidation()" required />
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
          <mat-error *ngIf="projectProfile.controls.endDate.errors?.required">Please select date
          </mat-error>
          <mat-hint style="color: red" *ngIf="dateError">Please select date greater than start date
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="grid" [ngClass]="
      checkProjectTypeValue == 'audio' || checkProjectTypeValue == 'video'
        ? 'grid-cols-4 sm-grid-cols-1 grill'
        : checkProjectTypeValue == 'text'
        ? 'grid-cols-3 grill sm-grid-cols-1'
        : 'grid-cols-3 grill sm-grid-cols-1'
    ">
        <mat-form-field color="accent" class="matformfield">
          <mat-label>Project Type:</mat-label>
          <mat-select formControlName="projectType" (selectionChange)="checkProjectType($event)">
            <mat-option value="audio">Audio</mat-option>
            <mat-option value="video">Video</mat-option>
            <mat-option value="text">TEXT</mat-option>
          </mat-select>
          <mat-error> Please select one option </mat-error>
        </mat-form-field>

        <ng-container *ngIf="checkProjectTypeValue !== null">
          <div *ngIf="checkProjectTypeValue == 'audio'">
            <mat-form-field color="accent" class="matformfield">
              <mat-label>Audio Type:</mat-label>
              <mat-select formControlName="projectType" (selectionChange)="checkAudioValueType($event)">
                <mat-option *ngFor="let audio of audioOptionType" [value]="audio">{{ audio }}</mat-option>
              </mat-select>
              <mat-error> Please select one option </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="checkProjectTypeValue == 'video'">
            <mat-form-field color="accent" class="matformfield">
              <mat-label>Video Type:</mat-label>
              <mat-select formControlName="projectType" (selectionChange)="checkVideoType($event)">
                <mat-option *ngFor="let video of videos" [value]="video">{{
                  video
                  }}</mat-option>
              </mat-select>
              <mat-error> Please select one option </mat-error>
            </mat-form-field>
          </div>
        </ng-container>
        <div *ngIf="!isJesusFilmProject">
          <mat-form-field color="accent" class="matformfield">
            <mat-label>Testament Type:</mat-label>
            <mat-select formControlName="testmentType" (selectionChange)="validateTestimentType($event)">
              <mat-option value="oldTestament">Old Testament</mat-option>
              <mat-option value="newTestament">New Testament</mat-option>
            </mat-select>
            <mat-error> Please select one option </mat-error>
          </mat-form-field>
        </div>

        <div class="flex items-center">
          <mat-checkbox class="mx-2" (change)="checkOfflineStatus($event)" formControlName="text">Offline
          </mat-checkbox>
          <mat-checkbox class="mx-12" (change)="checkIsBookAllocation($event)" formControlName="isBookAllocated">Book
            Allocation</mat-checkbox>
        </div>

      </div>
      <div class="flex pt-2 pb-4 flex-wrap checkbox-container justify-between" style="margin-right:14rem;">

        <div class="  mr-12 mb-2">
          <mat-checkbox (change)="checknoCrossCheck($event)">Skip Cross Check</mat-checkbox>
        </div>

        <div class=" checkBoxStyle mx-12 mb-2">
          <mat-checkbox (change)="checknoCommunityCheck($event)">Skip Community Check</mat-checkbox>
        </div>

        <div class="checkBoxStyle mx-12 mb-2">
          <mat-checkbox (change)="checknoCitCheck($event)">Skip CIT Check</mat-checkbox>
        </div>

        <div class="checkBoxStyle mx-12 mb-2">
          <mat-checkbox (change)="checknoConsultantCheck($event)">Skip Consultant Check</mat-checkbox>
        </div>

      </div>
      <!-- Changed from Here -->
      <div class="comp">
        <!-- <div class=""> -->
        <!--Mtt-->
        <div formArrayName="mtts">
          <div *ngFor="
                let mtt of projectProfile['controls']['mtts']['controls'];
                let i = index
              ">
            <div class="flex" [formGroupName]="i" style="position: relative">
              <app-mt-translator [mttGroup]="projectProfile.controls.mtts.controls[i]" [changing]="changingMttValue"
                class="sm-w-100">
              </app-mt-translator>
              <div class="flex items-center justify-center flex-direction icons">
                <div [ngClass]="{
                      disableDeleteicon:
                        projectProfile.controls.mtts.controls.length === 1,
                      deleteicon:
                        projectProfile.controls.mtts.controls.length > 1
                    }">
                  <span (click)="deleteMtt(i)">
                    <mat-icon>delete</mat-icon>
                  </span>
                </div>
                <div class="addfacilitator">
                  <span (click)="addMtt()">
                    <mat-icon>add</mat-icon>
                  </span>
                </div>
                <!-- <div>
                    <mat-icon (click)="showTimeline('Mtt')"
                      ><span class="material-symbols-outlined">
                        av_timerss
                      </span></mat-icon
                    >
                  </div> -->
              </div>
            </div>
          </div>
        </div>
        <!--Facilitator-->
        <div formArrayName="facilitators">
          <div *ngFor="
                let facilitator of projectProfile['controls']['facilitators'][
                  'controls'
                ];
                let i = index
              ">
            <div [formGroupName]="i" class="flex" style="position: relative">
              <app-facilitator [facilitatorGroup]="projectProfile.controls.facilitators.controls[i]"
                [changing]="changingFacilitatorValue" class="sm-w-100">
              </app-facilitator>
              <div class="flex items-center justify-center flex-direction icons">
                <div [ngClass]="{
                      disableDeleteicon:
                        projectProfile.controls.facilitators.controls.length ===
                        1,
                      deleteicon:
                        projectProfile.controls.facilitators.controls.length > 1
                    }">
                  <span (click)="deleteFacilitator(i)">
                    <mat-icon>delete</mat-icon>
                  </span>
                </div>
                <div class="addfacilitator">
                  <span (click)="addFacilitator()">
                    <mat-icon>add</mat-icon>
                  </span>
                </div>
                <!-- <div>
                    <mat-icon (click)="showTimeline('Facilitator')"
                      ><span class="material-symbols-outlined">
                        av_timerss
                      </span></mat-icon
                    >
                  </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
        <!-- CIT -->
        <div formArrayName="cits">
          <div *ngFor="
              let cit of projectProfile['controls']['cits']['controls'];
              let i = index
            ">
            <div class="flex sm-w-100" [formGroupName]="i" style="position: relative">
              <app-cit [citGroup]="projectProfile.controls.cits.controls[i]" class="sm-w-100"></app-cit>
              <div class="flex items-center justify-center flex-direction icons">
                <div [ngClass]="{
                    disableDeleteicon:
                      projectProfile.controls.cits.controls.length === 1,
                    deleteicon: projectProfile.controls.cits.controls.length > 1
                  }">
                  <span (click)="deleteCit(i)">
                    <mat-icon>delete</mat-icon>
                  </span>
                </div>
                <div class="addfacilitator">
                  <span (click)="addCit()">
                    <mat-icon>add</mat-icon>
                  </span>
                </div>
                <!-- <div>
                  <mat-icon (click)="showTimeline('Cit')"
                    ><span class="material-symbols-outlined">
                      av_timerss
                    </span></mat-icon
                  >
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!--consultant-->
        <div formArrayName="consultants">
          <div *ngFor="
              let consultant of projectProfile['controls']['consultants'][
                'controls'
              ];
              let i = index
            ">
            <div class="flex" [formGroupName]="i" style="position: relative">
              <app-consultant [consultantGroup]="
                  projectProfile.controls.consultants.controls[i]
                " class="sm-w-100">
              </app-consultant>
              <div class="flex items-center justify-center flex-direction icons">
                <div [ngClass]="{
                    disableDeleteicon:
                      projectProfile.controls.consultants.controls.length === 1,
                    deleteicon:
                      projectProfile.controls.consultants.controls.length > 1
                  }">
                  <span (click)="deleteConsultant(i)">
                    <mat-icon>delete</mat-icon>
                  </span>
                </div>
                <div class="addfacilitator">
                  <span (click)="addConsultant()">
                    <mat-icon>add</mat-icon>
                  </span>
                </div>
                <!-- <div>
                  <mat-icon (click)="showTimeline('Consultant')"
                    ><span class="material-symbols-outlined">
                      av_timerss
                    </span></mat-icon
                  >
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <!--Technician-->
        <div formArrayName="technicians">
          <div *ngFor="
              let technician of projectProfile['controls']['technicians'][
                'controls'
              ];
              let i = index
            ">
            <div class="flex" [formGroupName]="i" style="position: relative">
              <app-technician [technicianGroup]="
                  projectProfile.controls.technicians.controls[i] 
                " class="sm-w-100">
              </app-technician>
              <div class="flex items-center justify-center flex-direction icons">
                <div [ngClass]="{
                    disableDeleteicon:
                      projectProfile.controls.technicians.controls.length === 1,
                    deleteicon:
                      projectProfile.controls.technicians.controls.length > 1
                  }">
                  <span (click)="deleteTechnician(i)">
                    <mat-icon>delete</mat-icon>
                  </span>
                </div>
                <div class="addfacilitator">
                  <span (click)="addTechnician()">
                    <mat-icon>add</mat-icon>
                  </span>
                </div>
                <!-- <div>
                  <mat-icon (click)="showTimeline('Technician')"
                    ><span class="material-symbols-outlined">
                      av_timerss
                    </span></mat-icon
                  >
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <!--Coordinator-->
        <div formArrayName="coordinators">
          <div *ngFor="
              let coordinator of projectProfile['controls']['coordinators'][
                'controls'
              ];
              let i = index
            ">
            <div class="flex" [formGroupName]="i" style="position: relative">
              <app-coordinator [coOrdinatorGroup]="
                  projectProfile.controls.coordinators.controls[i]" class="sm-w-100">
              </app-coordinator>
              <div class="flex items-center justify-center flex-direction icons">
                <div [ngClass]="{
                    disableDeleteicon:
                      projectProfile.controls.coordinators.controls.length ===
                      1,
                    deleteicon:
                      projectProfile.controls.coordinators.controls.length > 1
                  }">
                  <span (click)="deleteCoOrdinator(i)">
                    <mat-icon>delete</mat-icon>
                  </span>
                </div>
                <div class="addCoOrdinator">
                  <span (click)="addCoOrdinator()">
                    <mat-icon>add</mat-icon>
                  </span>
                </div>
                <!-- <div>
                  <mat-icon (click)="showTimeline('Coordinator')"
                    ><span class="material-symbols-outlined">
                      av_timerss
                    </span></mat-icon
                  >
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <!-- Translation Manager -->
        <div formArrayName="translateManagers">
          <div *ngFor="
              let translateManager of projectProfile['controls']['translateManagers'][
                'controls'
              ];
              let i = index
            ">
            <div class="flex" [formGroupName]="i" style="position: relative">
              <app-translate-manager class="sm-w-100" [translateGroup]="
                  projectProfile.controls.translateManagers.controls[i]" [changing]="changingtranslateValue">
              </app-translate-manager>
              <div class="flex items-center justify-center flex-direction icons">
                <div [ngClass]="{
                    disableDeleteicon:
                      projectProfile.controls.translateManagers.controls.length === 1,
                    deleteicon:
                      projectProfile.controls.translateManagers.controls.length > 1
                  }">
                  <span (click)="deletetranslateManager(i)">
                    <mat-icon>delete</mat-icon>
                  </span>
                </div>
                <div class="addtranslateManager">
                  <span (click)="addtranslateManager()">
                    <mat-icon>add</mat-icon>
                  </span>
                </div>
                <!-- <div>
                  <mat-icon (click)="showTimeline('Translate Manager')"
                    ><span class="material-symbols-outlined">
                      av_timerss
                    </span></mat-icon
                  >
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div formArrayName="communities">
          <div *ngFor="
              let cit of projectProfile['controls']['communities']['controls'];
              let i = index
            ">
            <div class="flex sm-w-100" [formGroupName]="i" style="position: relative">
              <app-community [communityGroup]="projectProfile.controls.communities.controls[i]"
                class="sm-w-100"></app-community>
              <div class="flex items-center justify-center flex-direction icons">
                <div [ngClass]="{
                    disableDeleteicon:
                      projectProfile.controls.communities.controls.length === 1,
                    deleteicon: projectProfile.controls.communities.controls.length > 1
                  }">
                  <span (click)="deleteCommunity(i)">
                    <mat-icon>delete</mat-icon>
                  </span>
                </div>
                <div class="addfacilitator">
                  <span (click)="addCommunity()">
                    <mat-icon>add</mat-icon>
                  </span>
                </div>
                <!-- <div>
                  <mat-icon (click)="showTimeline('Cit')"
                    ><span class="material-symbols-outlined">
                      av_timerss
                    </span></mat-icon
                  >
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="button-row">
        <button mat-raised-button class="mr-3" [ngClass]="{
            'btn-primary rounded-sm':
              projectProfile.valid &&
              majorLang1.value !== majorLang2.value &&
              !isLanguageTranslated
          }" (click)="submitData(); projectProfile['submitted'] = false" [disabled]="
          !projectProfile.valid ||
            majorLang1.value === majorLang2.value ||
            isLanguageTranslated
  
          ">
          Create Project
        </button>
      </div>
    </mat-card-content>
  </form>

</mat-card>