<ng-container *ngIf="isDisplay">
  <div class="tab-section font-primary">
    <div>
      <mat-tab-group
        animationDuration="0ms"
        mat-align-tabs="center"
        [selectedIndex]="selectedIndex"
        (selectedTabChange)="setTabInfo($event)"
      >
        <mat-tab label="MTT">
          <ng-container *ngTemplateOutlet="usersData"> </ng-container>
        </mat-tab>
        <mat-tab label="Facilitator">
          <ng-container *ngTemplateOutlet="usersData" class="font-primary">
          </ng-container>
        </mat-tab>
        <mat-tab label="CIT">
          <ng-container *ngTemplateOutlet="usersData"> </ng-container>
        </mat-tab>
        <mat-tab label="Consultant">
          <ng-container *ngTemplateOutlet="usersData"> </ng-container>
        </mat-tab>

        <mat-tab label="Technician">
          <ng-container *ngTemplateOutlet="usersData"> </ng-container>
        </mat-tab>
        <mat-tab label="Coordinator">
          <ng-container *ngTemplateOutlet="usersData"> </ng-container>
        </mat-tab>
        <mat-tab label="Country Coordinator">
          <ng-container *ngTemplateOutlet="usersData"> </ng-container>
        </mat-tab>
        <mat-tab label="Translation Manager">
          <ng-container *ngTemplateOutlet="usersData"> </ng-container>
        </mat-tab>
        <mat-tab label="Community">
          <ng-container *ngTemplateOutlet="usersData"> </ng-container>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <ng-template #usersData>
    <mat-card
      class="user-section font-primary"
      *ngIf="usersDetails && usersDetails.length"
      [ngClass]="{ dynamicCard: usersDetails.length <= 5 }"
    >
      <div class="user-results">
        <mat-card-header class="background-primary border-top-5">
          <mat-card-title>Users Data</mat-card-title>
        </mat-card-header>
        <div class="language-table font-primary">
          <table
            mat-table
            [dataSource]="dataSource"
            matSort
            class="mat-elevation-z8 font-primary"
            *ngIf="usersDetails && usersDetails.length"
          >
            <ng-container matColumnDef="Position">
              <th mat-header-cell *matHeaderCellDef class="header-format">
                S.No
              </th>
              <td mat-cell *matCellDef="let element; let i = index">
                {{ itemSerialNumber + i }}
              </td>
            </ng-container>
            <ng-container matColumnDef="UserName">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="header-format font-primary"
              >
                Username
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="data-format"
                matTooltipPosition="left"
                matTooltip="{{
                  element.role == 'Consultant' ||
                  element.role === 'CIT' ||
                  element.role === 'Technician'
                    ? 'View Projects'
                    : ''
                }}"
                (click)="getProjectList(element)"
              >
                {{ element.firstName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Email">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="header-format"
              >
                Email
              </th>
              <td mat-cell *matCellDef="let element" class="data-format">
                {{ element.email }}
              </td>
            </ng-container>

            <ng-container
              matColumnDef="Language"
              *ngIf="currentTab !== ('Consultant' || 'CIT' || 'Technician')"
            >
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="header-format"
              >
                Project
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="data-format capitalize"
              >
                {{ element.language }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Edit/Delete">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="header-format"
              >
                Action
              </th>
              <td mat-cell *matCellDef="let element">
                <button
                  class="edit-delete btn-primary"
                  [routerLink]="['/edit-user', currentTab, element._id]"
                >
                  Edit
                </button>
                <button
                  class="edit-delete delete-btn btn-primary"
                  (click)="openDialog(element)"
                >
                  Delete
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </mat-card>
    <mat-card class="no-users" *ngIf="usersDetails && usersDetails.length < 1">
      <h3>Users Not Found!</h3>
    </mat-card>
  </ng-template>
  <div [hidden]="!(usersDetails && usersDetails.length > 5)">
    <mat-card class="pagination">
      <mat-paginator
        (page)="changePage($event)"
        class="mat-paginator-outer-container"
        [pageSizeOptions]="[5, 10, 20, 30, 40, 50]"
        [pageSize]="20"
        showFirstLastButtons
      ></mat-paginator>
    </mat-card>
  </div>
</ng-container>
