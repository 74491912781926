import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogsService } from 'src/app/services/dialog/dialog.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { ProjectProfileService } from 'src/app/services/project-profile/project-profile.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';
import { PopUpModalComponent } from '../../components/pop-up-modal/pop-up-modal.component';
import { ReplaySubject, Subject } from 'rxjs';
import { TimelineComponent } from 'src/app/components/timeline/timeline.component';
import { BibleBooksService } from 'src/app/services/bible-book/bible-book.service';
export interface RoleData {
  projectId: string;
  userId: string;
  role: string;
  timeLineDetails: TimeLineDetails[];
}

export interface Chapter {
  id?: string;
  chapterName: string;
  noOfDays: number;
}
export interface TimeLineDetails {
  bookName: string;
  stageName: string;
  id?: string;
  noOfDays: number;
  chapters: Chapter[];
}

export interface TimeLine {
  userId?: string;
  projectId: string;
  role: string;
  timeLineDetails: TimeLineDetails[];
}

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.scss'],
})
export class ProjectEditComponent implements OnInit {
  isDisplay: boolean = false;
  projectId: any;
  projRolefacilitator: any;
  projRoleMTT: any;
  projRoleConsultant: any;
  projRoleCit: any;
  projRoleTechnician: any;
  projRoleCoordinator: any;
  projRoleTranslate: any;
  projRoleCommunity: any;
  duplicateUsers: any;
  isAdmin: any;
  changingFacilitatorValue: any = new ReplaySubject(2);
  changingMttValue: any = new ReplaySubject(2);
  changingTranslateValue: any = new ReplaySubject(2);
  changingCommunityValue: any = new ReplaySubject(2);
  changingCoordinatorValue: any = new ReplaySubject(2);
  changingCitValue: any = new ReplaySubject(2);
  editProject: FormGroup | any;
  ProjectTypeError: boolean | any;
  TestmentError: boolean | any;
  dateError: boolean | any;
  facilitatorData: any[] | any;
  mttData: any[] | any;
  translateData: any[] | any;
  citData: any[] | any;
  coordinatorData: any[] | any;
  countriesList: any[] | any;
  regions: any[] | any;
  languages: any[] | any;
  sourceLanguages: Array<object> | any;
  projectType: any;
  isJesusFilmProject: boolean | any;
  isTechnicianExist: boolean | any;
  isTranslateManagerExist: boolean | any;
  isCoOrdinatorExist: boolean | any;
  isCommunityExits: boolean | any;
  requiredFileds = [
    'projectName',
    'projectLocation',
    'audio',
    'text',
    'videoType',
    'language',
    'oldTestament',
    'newTestament',
    'partner',
    'majorLanguage1',
    'majorLanguage2',
    'majorLanguage3',
    'startDate',
    'endDate',
    'isSyncNeed',
    'isBookAllocated',
    'noCrossCheck',
    'noCommunityCheck',
    'noCitCheck',
    'noConsultantCheck',
  ];
  projectTypeValue: any = 'Audio';
  videoTypeValue: any;
  audioTypeValue: any;
  testamentTypeValue: any = 'New';
  noCrossCheck: boolean = false;
  noCommunityCheck: boolean = false;
  noCitCheck: boolean = false;
  noConsultantCheck: boolean = false;

  constructor(
    private bibleBooksService: BibleBooksService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userDataService: UserDataService,
    private projectProfileService: ProjectProfileService,
    private router: Router,
    private dialog: MatDialog,
    private dialogService: DialogsService,
    private languageService: LanguageService
  ) {
    this.isAdmin = sessionStorage.getItem('isAdmin');
    this.projRolefacilitator = [];
    this.projRoleMTT = [];
    this.projRoleConsultant = [];
    this.projRoleCit = [];
    this.projRoleTechnician = [];
    this.projRoleTranslate = [];
    this.projRoleCoordinator = [];
    this.projRoleCommunity = [];
    this.isJesusFilmProject = false;
    this.isTechnicianExist = false;
  }

  async ngOnInit() {
    this.dialogService.confirm();
    this.createFormControls();
    this.regions = await this.getRegions();
    this.getSourceLanguages();
    this.activatedRoute.params.subscribe((params) => {
      this.projectId = params['id'];
      this.projectProfileService
        .getProjectById(this.projectId)
        .subscribe(async (response) => {
          this.basicConditionCheck(response);
          this.requiredFileds.forEach((data) => {
            if (data === 'text') {
              this.editProject.controls[data].setValue(
                response.projectType[data]
              );

              //const projectType = Object.keys(response.projectType).filter(k => response.projectType[k] === true);
              // if (response.projectType) {
              //   if(response.projectType.signLanguage==true && response.projectType.text==true){
              //     //this.projectTypeValue = 'Video';
              //     this.editProject.controls['videoType'].setValue(
              //       response.projectType['videoType']
              //     );
              //   }
              // }
            } else if (data === 'audio') {
              //(data === 'audioType')
              this.editProject.controls[data].setValue(
                response.projectType[data]
              );
            } else if (data === 'videoType') {
              this.projectType = response.projectType;
              this.editProject.controls[data].setValue(
                response.projectType[data]
              );
              // delete obj['audio'];
              // delete obj['text'];
              //let obj = response.projectType;
              // let key = Object.keys(obj).find((k) => obj[k] === true);
              let key, value;
              if (
                response.projectType.signLanguage == true &&
                response.projectType.text == true
              ) {
                value = response.projectType.signLanguage;
                key = 'Sign Language';
              } else if (response.projectType.signLanguage == true) {
                value = response.projectType.signLanguage;
                key = 'Sign Language';
              }
              this.editProject.controls[data].setValue(value);
              key === 'jesusFilm'
                ? (this.isJesusFilmProject = true)
                : (this.isJesusFilmProject = false);
            } else {
              if (data === 'language') {
                this.bindProjectRoles(response);
                this.setProjectRoleData(response);
              }
              this.editProject.controls[data].setValue(response[data]);
            }
          });
          this.noCitCheck = response.noCitCheck;
          this.noCrossCheck = response.noCrossCheck;
          this.noCommunityCheck = response.noCommunityCheck;
          this.noConsultantCheck = response.noConsultantCheck;

          this.dialogService.closeModal();
          this.isDisplay = true;
        });
    });
  }

  bindProjectRoles(project: any) {
    project.facilitator.forEach((data: any) => {
      this.addFacilitator();
      this.projRolefacilitator.push(data);
    });

    project.MTT.forEach((data: any) => {
      this.addMtt();
      console.log('data MTT', data);
      this.projRoleMTT.push(data);
    });

    project.consultant.forEach((data: any) => {
      this.addConsultant();
      this.projRoleConsultant.push(data);
    });

    project.cit.forEach((data: any) => {
      this.addCit();
      this.projRoleCit.push(data);
    });

    this.isCoOrdinatorExist = false;
    project.coordinator.forEach((data: any) => {
      this.addCoOrdinator();
      this.isCoOrdinatorExist = true;
      this.projRoleCoordinator.push(data);
    });
    if (!this.isCoOrdinatorExist) {
      this.addCoOrdinator();
    }

    this.isTranslateManagerExist = false;
    project.translateManager.forEach((data: any) => {
      this.addtranslateManager();
      this.isTranslateManagerExist = true;
      this.projRoleTranslate.push(data);
    });
    if (!this.isTranslateManagerExist) {
      this.addtranslateManager();
    }

    this.isCommunityExits = false;
    project.community.forEach((data: any) => {
      this.addCommunity();
      this.isCommunityExits = true;
      this.projRoleCommunity.push(data);
    });
    if (!this.isCommunityExits) {
      this.addCommunity();
    }

    this.isTechnicianExist = false;
    project.technician.forEach((data: any) => {
      this.addTechnician();
      this.isTechnicianExist = true;
      this.projRoleTechnician.push(data);
    });

    if (!this.isTechnicianExist) {
      this.addTechnician();
    }

    this.userDataService
      .getUsersByLanguage(project.language)
      .subscribe((userData) => {
        this.sendData(userData);
      });
  }

  sendData(data: any) {
    this.facilitatorData = data.filter((user: any) => {
      if (user.role === 'Facilitator') {
        return user;
      }
    });
    this.mttData = data.filter((user: any) => {
      if (user.role === 'MTT') {
        return user;
      }
    });
    this.citData = data.filter((user: any) => {
      if (user.role === 'CIT') {
        return user;
      }
    });
    this.translateData = data.filter((user: any) => {
      if (user.role === 'Translation Manager') {
        return user;
      }
    });
    this.coordinatorData = data.filter((user: any) => {
      if (user.role === 'Coordinator') {
        return user;
      }
    });

    this.changingFacilitatorValue.next(this.facilitatorData);
    this.changingMttValue.next(this.mttData);
    this.changingCitValue.next(this.citData);
    this.changingTranslateValue.next(this.translateData);
    this.changingCommunityValue.next(this.translateData);
    this.changingCoordinatorValue.next(this.coordinatorData);
  }

  createFormControls() {
    this.editProject = new FormGroup({
      projectName: new FormControl('', Validators.required),
      country: new FormControl({ value: '', disabled: true }),
      audio: new FormControl({ value: '', disabled: true }),
      text: new FormControl({ value: '', disabled: true }),
      region: new FormControl({ value: '', disabled: true }),
      language: new FormControl({ value: '', disabled: true }),
      projectLocation: new FormControl('', Validators.required),
      majorLanguage1: new FormControl({ value: '', disabled: true }),
      majorLanguage2: new FormControl({ value: '', disabled: true }),
      majorLanguage3: new FormControl({ value: '', disabled: true }),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      oldTestament: new FormControl({ value: '', disabled: true }),
      newTestament: new FormControl({ value: '', disabled: true }),
      partner: new FormControl('', Validators.required),
      //others: new FormControl({value: '', disabled: true}),
      facilitators: this.formBuilder.array([]),
      mtts: this.formBuilder.array([]),
      consultants: this.formBuilder.array([]),
      cits: this.formBuilder.array([]),
      technicians: this.formBuilder.array([]),
      translateManagers: this.formBuilder.array([]),
      coordinators: this.formBuilder.array([]),
      communities: this.formBuilder.array([]),
      isSyncNeed: new FormControl({}),
      isBookAllocated: new FormControl({}),
      noCrossCheck: new FormControl({}),
      noCommunityCheck: new FormControl({}),
      noCitCheck: new FormControl({}),
      noConsultantCheck: new FormControl({}),
      videoType: new FormControl({ value: '', disabled: true }),
    });
  }

  createMtt() {
    return this.formBuilder.group({
      mtt: ['', Validators.required],
    });
  }

  addMtt() {
    const control = <FormArray>this.editProject.controls['mtts'];
    const addrCtrl = this.createMtt();
    control.push(addrCtrl);
  }

  deleteMtt(i: number) {
    if (this.editProject.controls.mtts['controls'].length > 1) {
      const control = <FormArray>this.editProject.controls['mtts'];
      control.removeAt(i);
    } else {
      return;
    }
  }

  createFacilitator() {
    return this.formBuilder.group({
      facilitator: ['', Validators.required],
    });
  }

  addFacilitator() {
    const control = <FormArray>this.editProject.controls['facilitators'];
    const addrCtrl = this.createFacilitator();
    control.push(addrCtrl);
  }

  deleteFacilitator(i: number) {
    if (this.editProject.controls.facilitators['controls'].length > 1) {
      const control = <FormArray>this.editProject.controls['facilitators'];
      control.removeAt(i);
    } else {
      return;
    }
  }

  createConsultant() {
    return this.formBuilder.group({
      consultant: ['', Validators.required],
    });
  }

  addConsultant() {
    const control = <FormArray>this.editProject.controls['consultants'];
    const addrCtrl = this.createConsultant();
    control.push(addrCtrl);
  }

  deleteConsultant(i: number) {
    if (this.editProject.controls.consultants['controls'].length > 1) {
      const control = <FormArray>this.editProject.controls['consultants'];
      control.removeAt(i);
    } else {
      return;
    }
  }

  createCit() {
    return this.formBuilder.group({
      cit: ['', Validators.required],
    });
  }

  addCit() {
    const control = <FormArray>this.editProject.controls['cits'];
    const addrCtrl = this.createCit();
    control.push(addrCtrl);
  }

  deleteCit(i: number) {
    if (this.editProject.controls.cits['controls'].length > 1) {
      const control = <FormArray>this.editProject.controls['cits'];
      control.removeAt(i);
    } else {
      return;
    }
  }

  createTechnician() {
    return this.formBuilder.group({
      technician: ['', Validators.required],
    });
  }

  addTechnician() {
    const control = <FormArray>this.editProject.controls['technicians'];
    const addrCtrl = this.createTechnician();
    control.push(addrCtrl);
  }

  deleteTechnician(i: number) {
    if (this.editProject.controls.technicians['controls'].length > 1) {
      const control = <FormArray>this.editProject.controls['technicians'];
      control.removeAt(i);
    } else {
      return;
    }
  }

  createCoOrdinator() {
    return this.formBuilder.group({
      coordinator: ['', Validators.required],
    });
  }

  addCoOrdinator() {
    const control = <FormArray>this.editProject.controls['coordinators'];
    const addrCtrl = this.createCoOrdinator();
    control.push(addrCtrl);
  }

  deleteCoOrdinator(i: number) {
    if (this.editProject.controls.coordinators['controls'].length > 1) {
      const control = <FormArray>this.editProject.controls['coordinators'];
      control.removeAt(i);
    } else {
      return;
    }
  }
  createtranslateManager() {
    return this.formBuilder.group({
      translateManager: ['', Validators.required],
    });
  }

  addtranslateManager() {
    const control = <FormArray>this.editProject.controls['translateManagers'];
    const addrCtrl = this.createtranslateManager();
    control.push(addrCtrl);
  }

  deletetranslateManager(i: number) {
    if (this.editProject.controls.translateManagers['controls'].length > 1) {
      const control = <FormArray>this.editProject.controls['translateManagers'];
      control.removeAt(i);
    } else {
      return;
    }
  }
  createCommunity() {
    return this.formBuilder.group({
      community: ['', Validators.required],
    });
  }

  addCommunity() {
    const control = <FormArray>this.editProject.controls['communities'];
    const addrCtrl = this.createCommunity();
    control.push(addrCtrl);
  }

  deleteCommunity(i: number) {
    if (this.editProject.controls.communities['controls'].length > 1) {
      const control = <FormArray>this.editProject.controls['communities'];
      control.removeAt(i);
    } else {
      return;
    }
  }

  validateTestimentType() {
    const newTestiment = this.editProject.get('newTestament').value;
    const oldTestiment = this.editProject.get('oldTestament').value;
    //const others = this.editProject.get('others').value;
    //if (!(newTestiment || oldTestiment || others)) {
    if (!(newTestiment || oldTestiment)) {
      this.TestmentError = true;
    } else {
      this.TestmentError = false;
    }
  }

  validateProjectType() {
    const audio = this.editProject.get('audio').value;
    const text = this.editProject.get('text').value;
    const videoType = this.editProject.get('videoType').value;
    if (!(audio || text || videoType)) {
      this.ProjectTypeError = true;
    } else {
      this.ProjectTypeError = false;
    }
  }

  dateValidation() {
    const startDate = this.editProject.get('startDate').value;
    const endDate = this.editProject.get('endDate').value;
    if (new Date(startDate) > new Date(endDate)) {
      this.dateError = true;
    } else {
      this.dateError = false;
    }
  }

  async getRegions() {
    return await this.languageService.getRegions().toPromise();
  }

  setProjectRoleData(project: any) {
    let index = this.regions.findIndex(
      (res: any) => res.regionName === project.region
    );
    this.editProject.controls['region'].setValue(this.regions[index]);

    const regionId = this.regions[index].id;
    this.languageService
      .getCountriesByRegion(regionId)
      .subscribe((response) => {
        this.countriesList = response;
        index = this.countriesList.findIndex(
          (res: any) => res.countryName === project.country
        );
        this.editProject.controls['country'].setValue(
          this.countriesList[index]
        );

        const countryId = this.countriesList[index].id;
        this.languageService
          .getLanguagesByCountry(countryId)
          .subscribe((responsemore) => {
            this.languages = responsemore;
            index = this.languages.findIndex(
              (res: any) => res.languageName === project.language
            );
          });
      });
  }

  getCountries(event: any) {
    const regionId = event.value.id;
    this.languageService
      .getCountriesByRegion(regionId)
      .subscribe((response) => {
        this.countriesList = response;
      });
  }

  getLanguages(event: any) {
    const countryId = event.value.id;
    this.languageService
      .getLanguagesByCountry(countryId)
      .subscribe((response) => {
        this.languages = response;
      });
  }

  getSourceLanguages() {
    this.languageService.getSourcLanguages().subscribe((response) => {
      this.sourceLanguages = response;
    });
  }

  updateProject() {
    this.validateProjectType();
    this.validateTestimentType();
    this.dateValidation();
    //let videoType = this.editProject.get('videoType').value;
    const typeofProject = {
      audio: !!this.editProject.get('audio').value,
      text: !!this.editProject.get('text').value,
      jesusFilm: this.videoTypeValue == 'Jesus Film' ? true : false,
      signLanguage: this.videoTypeValue == 'Sign Language' ? true : false,
    };
    const initialFacilitator =
      this.editProject.controls.facilitators['controls'];
    const facilitator = initialFacilitator.map((facilitators: any) => {
      return { _id: facilitators.value.facilitator._id };
    });
    const initialMtts = this.editProject.controls.mtts['controls'];
    const mtts = initialMtts.map((MTT: any) => {
      return { _id: MTT.value.mtt._id };
    });
    const initialConsultant = this.editProject.controls.consultants['controls'];
    const consultant = initialConsultant.map((consultants: any) => {
      return { _id: consultants.value.consultant._id };
    });
    const initialCit = this.editProject.controls.cits['controls'];
    const cit = initialCit.map((CIT: any) => {
      return { _id: CIT.value.cit._id };
    });
    const initialTechnician = this.editProject.controls.technicians['controls'];
    const technician = initialTechnician.map((technician: any) => {
      return { _id: technician.value.technician._id };
    });
    const initialCoordinator =
      this.editProject.controls.coordinators['controls'];
    const coordinator = initialCoordinator.map((coordinator: any) => {
      return { _id: coordinator.value.coordinator._id };
    });
    const initialtranslateManager =
      this.editProject.controls.translateManagers['controls'];
    const translateManager = initialtranslateManager.map(
      (translateManagers: any) => {
        return { _id: translateManagers.value.translateManager._id };
      }
    );

    const initialCommunity = this.editProject.controls.communities['controls'];
    const community = initialCommunity.map((user: any) => {
      return { _id: user.value.community._id };
    });

    const users = [
      this.editProject.getRawValue().consultants,
      this.editProject.getRawValue().mtts,
      this.editProject.getRawValue().facilitators,
      this.editProject.getRawValue().cits,
      this.editProject.getRawValue().technicians,
      this.editProject.getRawValue().coordinators,
      this.editProject.getRawValue().translateManagers,
      this.editProject.getRawValue().communities,
    ];
    this.duplicateUsers = this.projectProfileService.validateUserDuplication(
      ...users
    );
    if (
      this.ProjectTypeError ||
      this.dateError ||
      this.TestmentError ||
      this.duplicateUsers.length
    ) {
      window.scrollTo(0, 100);
      // return;
    }
    this.dialogService.confirm();
    const projectData = {
      id: this.projectId,
      projectName: this.editProject.get('projectName').value,
      projectType: typeofProject,
      country: this.editProject.get('country').value.countryName,
      region: this.editProject.get('region').value.regionName,
      language: this.editProject.get('language').value,
      projectLocation: this.editProject.get('projectLocation').value,
      majorLanguage1: this.editProject.get('majorLanguage1').value,
      majorLanguage2: this.editProject.get('majorLanguage2').value,
      majorLanguage3: this.editProject.get('majorLanguage3').value,
      startDate: this.editProject.get('startDate').value,
      endDate: this.editProject.get('endDate').value,
      oldTestament: !!this.editProject.get('oldTestament').value,
      newTestament: !!this.editProject.get('newTestament').value,
      partner: this.editProject.get('partner').value,
      facilitators: facilitator,
      mtts: mtts,
      consultants: consultant,
      cits: cit,
      technicians: technician,
      coordinators: coordinator,
      translateManagers: translateManager,
      community: community,
      showTimeLine: this.projectProfileService.getTimeLineInfo(),
      isSyncNeed: !!this.editProject.get('isSyncNeed').value,
      isBookAllocated: !!this.editProject.get('isBookAllocated').value,
      noCrossCheck: !!this.editProject.get('noCrossCheck').value,
      noCommunityCheck: !!this.editProject.get('noCommunityCheck').value,
      noCitCheck: !!this.editProject.get('noCitCheck').value,
      noConsultantCheck: !!this.editProject.get('noConsultantCheck').value,
    };
    console.log('Project data:', projectData);
    this.projectProfileService
      .updateProjectById(this.projectId, projectData)
      .subscribe(
        async (projectsData) => {
          if (projectsData instanceof HttpResponse) {
            if (projectsData.status === 200) {
              this.dialog.open(PopUpModalComponent, {
                data: { message: 'Project Updated Successfully' },
              });
              await wait();
              this.editProject.reset();
              this.dialog.closeAll();
              await this.dialogService.closeModal();
              this.router.navigate(['/dashboard']);
            }
          }
        },
        (error) => {
          if (error instanceof HttpErrorResponse) {
            this.dialog.open(PopUpModalComponent, {
              data: { message: 'Project Not Updated' },
            });
          }
        }
      );
  }

  basicConditionCheck(res: any) {
    const projectType = Object.keys(res.projectType).filter(
      (k) => res.projectType[k] === true
    );

    if (res.projectType) {
      if (
        res.projectType.jesusFilm == true ||
        res.projectType.signLanguage == true
      ) {
        this.projectTypeValue = 'Video';
      }
    }
    if (res.projectType.audio == false && res.projectType.text == true) {
      this.projectTypeValue = 'TEXT';
    }

    //SignApp and text change
    if (res.projectType.signLanguage == true && res.projectType.text == true) {
      this.projectTypeValue = 'Video';
    }

    if (projectType.includes('audio') && projectType.includes('text')) {
      this.videoTypeValue = 'OBT and TEXT';
    } else if (projectType.includes('audio')) {
      this.videoTypeValue = 'OBT';
    }

    if (res.projectType.jesusFilm == true) {
      this.projectProfileService.isJesusFilmProject('Jesus Film');
      this.videoTypeValue = 'Jesus Film';
    }

    if (projectType.includes('signLanguage') && projectType.includes('text')) {
      this.videoTypeValue = 'Sign Language';
    }
    if (res.projectType.signLanguage == true) {
      this.videoTypeValue = 'Sign Language';
    }
    if (res.newTestament == true) {
      this.testamentTypeValue = 'New';
    }
    if (res.oldTestament == true) {
      this.testamentTypeValue = 'Old';
    }
    if (res.oldTestament == false && res.newTestament == false) {
      this.testamentTypeValue = '';
    }
  }
}

const wait = async () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('afd');
    }, 1500);
  });
};
